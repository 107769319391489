
const parkingDetails = {
  data: {},
  isLoading: false,
  error: null,
};

// This is the function we use to update the state of redux it initiailly as above. Any where in the apllication programer can call this reducers to update the redux.

function parkingDetailsReducer(state = parkingDetails, action) {
  switch (action.type) {
    case "FETCH_PARKING_SUCCESS":
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    case "FETCH_PARKING_ERROR":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case "FETCH_PARKING_LOADING":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "CLEAR_PARKING":
      return {
        ...parkingDetails,
      };
    default:
      return state;
  }
}
export { parkingDetailsReducer };

