import styled from "styled-components";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 50px); */
  padding-bottom: 50px;
  background-color: #f3f8fb;
  &.notfixed {
    padding-bottom: 0px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 40%;
  padding: 20px;
  padding-left: 100px;
  margin-bottom: 60px;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 768px) {
    flex: 1 1 100%;
    width: auto;
    padding: 10px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 64vh;
    object-fit: cover;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    &.hm {
      display: none;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 9px 0px;
  background-color: white;
  img {
    height: 30px;
  }
`;

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 0;
    margin-top: 50px;
  }
  p {
    display: flex;
    margin-bottom: 50px;
  }
`;
export const Label = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
`;

export const Center = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  svg {
    height: 5em;
    color: rgb(0, 122, 204);
    margin-bottom: 1.5em;
  }
  &.round svg
  {
    border: 3px solid;
    border-radius: 50%;
    width: 5em;
    padding: 12px;    
  }
  p,i {
    font-size:16px;
  }
`;
