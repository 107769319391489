import React, { useEffect, useRef, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { postData } from "../../../backend/api";
import Footer from "../footer";
import { Center, Label, MainContainer } from "./styles";
//using translation
import { useTranslation } from "react-i18next";
// import { clearVehicle, fetchVehicle } from "../../../store/actions/vehicle";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "../layout";
import { ColumnContainer, RowContainer } from "../../styles/containers/styles";
import Header1 from "../header";
import FormInput from "../../elements/input";
import { Form, Header, Overlay, Page } from "../../elements/form/styles";
import { InputContainer } from "../../elements/input/styles";
import { germany, inernational } from "../../../images";
import { CloseIcon, TickIcon } from "../../../icons";
import { postData } from "../../../backend/api";
import { useParams } from "react-router-dom";
import { clearParking, fetchParking } from "../../../store/actions/parking";

const Login = (props) => {
  useEffect(() => {
    document.title = `Guest Portal`;
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { parkingid } = useParams();
  const parking = useSelector((state) => state.parking);
  const { t } = useTranslation();

  const { setLoaderBox } = props;
  useEffect(() => {
    setLoaderBox(parking.isLoading);
    if (parking.error !== null) {
      setIsValid(false);
      dispatch(clearParking());
    } else {
      if (parking.data.data) setFormValues({ licensePlate: "", maximumParking: `${parking.data.data.hours} ${t("hours")}`, validity: parking.data.data.datetime, licenseCode: "", foreignPlate: "" });
    }
  }, [parking, navigate, setLoaderBox, t, props, dispatch]);

  useEffect(() => {
    dispatch(fetchParking(parkingid));
  }, [parkingid, dispatch]);

  const [formState] = useState([
    { type: "text", placeholder: "DAHIP12345", name: "foreignPlate", validation: "text", default: "", label: "foreignPlate", minimum: 5, maximum: 12, required: true },
    { type: "info", placeholder: "maximumParking", name: "maximumParking", validation: "maximumParking", default: "4 Stunden", label: "maximumParking", minimum: 5, maximum: 200, required: false },
    { type: "info", placeholder: "validity", name: "validity", validation: "validity", default: "03/04/2023 | 14:20 ", label: "validity", minimum: 5, maximum: 200, required: true },
    { type: "text", placeholder: "DAH", name: "licenseCode", validation: "text", default: "", label: "licenseCode", minimum: 3, maximum: 3, required: true },
    { type: "text", placeholder: "IP12345", name: "licensePlate", validation: "text", default: "", label: "licensePlate", minimum: 5, maximum: 8, required: true },
    { type: "info", placeholder: "licensePlate", name: "licensePlate", validation: "licensePlate", default: "", label: "licensePlate", minimum: 5, maximum: 12, required: true },
  ]);

  const [formErrors, setFormErrors] = useState({ licensePlate: "", maximumParking: "", validity: "", licenseCode: "", foreignPlate: "" });
  const [formValues, setFormValues] = useState({ licensePlate: "", maximumParking: "4 Stunden", validity: "03/04/2023 | 14:20", licenseCode: "", foreignPlate: "" });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  //State to store Captcha Status Validations Status
  const [captchaStatus] = useState(false);
  //State to store Agreement Status Status
  const [agreementStatus, setAgreementStatus] = useState(false);
  //State to store Captcha Status Validations Status
  const [extended, setSxtended] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [extendedLicnensePlate, setExtendedLicnensePlate] = useState("");
  const catchaValidation = (captchaStatus, useCaptcha) => {
    let flag = 0;
    let tempformError = "";
    if (captchaStatus === false && useCaptcha === true) {
      tempformError = t("required", { label: t("captcha") });
      flag += 1;
    }
    return { flag, tempformError };
  };
  const agreementValidation = (agreement, useCheckbox) => {
    let flag = 0;
    let tempformError = "";
    if (agreement !== true && useCheckbox === true) {
      tempformError = t("required", { label: t("agreement") });
      flag += 1;
    }
    return { flag, tempformError };
  };
  const validation = (fields, udpatedValue, formErrors, captchaStatus, useCaptcha, agreement, useCheckbox, isGermanStatus) => {
    const tempformErrors = { ...formErrors };
    let flags = 0;
    fields.forEach((item) => {
      if (item.name !== "_id") {
        if (item.type === "multiple") {
          item.forms.forEach((form, multipleIndex) => {
            form.forEach((inputs, index) => {
              const res = fieldValidation(inputs, typeof udpatedValue[item.name][multipleIndex][inputs.name] === "undefined" ? "" : udpatedValue[item.name][multipleIndex][inputs.name]);
              tempformErrors[item.name][multipleIndex][inputs.name] = res.tempformError;
              flags += res.flag; //?res.flag:0;
            });
          });
        } else if (item.validation === "greater") {
          const res = fieldValidation(item, typeof udpatedValue[item.name] === "undefined" ? "" : udpatedValue[item.name], typeof udpatedValue[item.reference] === "undefined" ? new Date() : udpatedValue[item.reference]);
          tempformErrors[item.name] = res.tempformError;
          flags += res.flag; //?res.flag:0;
        } else {
          if (isGermanStatus) {
            if (item.name === "foreignPlate") {
              return;
            }
          } else {
            if (item.name === "licenseCode") {
              return;
            } else if (item.name === "licensePlate") {
              return;
            }
          }
          const res = fieldValidation(item, typeof udpatedValue[item.name] === "undefined" ? "" : udpatedValue[item.name]);
          tempformErrors[item.name] = res.tempformError;
          flags += res.flag; //?res.flag:0;
        }
      }
    });
    const captchaRes = catchaValidation(captchaStatus, useCaptcha);
    tempformErrors["captchaError"] = captchaRes.tempformError;
    flags += captchaRes.flag; //?res.flag:0;

    const agreementRes = agreementValidation(agreement, useCheckbox);
    tempformErrors["captchaError"] = agreementRes.tempformError;
    flags += agreementRes.flag; //?res.flag:0;
    setFormErrors(tempformErrors);
    setSubmitDisabled(flags > 0 ? true : false);
    if (flags === 0) {
      return true;
    } else {
      return false;
    }
  };

  const fieldValidation = (field, value, ref = new Date()) => {
    let flag = 0;
    let tempformError = "";

    if (!field.update && props.formType === "put") {
      return { flag, tempformError };
    }

    if (!field.required && value.length === 0) {
      return { flag, tempformError };
    }

    switch (field.validation) {
      case "email":
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!regex.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "cvv":
        if (!/^[0-9]{3}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        } // German credit cards typically have a 3-digit CVV
        break;
      case "ccn":
        if (!/^[0-9]{16}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        let sum = 0;
        for (let i = 0; i < value.length; i++) {
          let digit = parseInt(value[i]);
          if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
              digit -= 9;
            }
          }
          sum += digit;
        }
        if (sum % 10 !== 0) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "fileNumber":
        const fileNumber = /[A-Z0-9-]/;
        if (!fileNumber.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "licensePlate":
        const german = /^[A-Z]{1,3}[ -]?[A-Z0-9]{2}[ -]?[A-Z0-9]{3,5}$/i;
        if (!german.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "greater":
        const referedDate = new Date(ref);
        if (new Date(value) < referedDate) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "amount":
        const amount = /^\d+([.,]\d{1,2})?$/;
        if (!amount.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "datetime":
      case "time":
        const date = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
        if (!date.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "text":
        break;
      default:
        break;
    }
    if (field.type === "image" || field.type === "file") {
      if (value.length === 0) {
        tempformError = t("validContent", { label: t(field.label) });
        flag += 1;
      }
    } else {
      if (field.required && value.length === 0) {
        tempformError = t("required", { label: t(field.label) });
        flag += 1;
      } else if (field.minimum > value.length) {
        tempformError = t("requiredMinimum", { minimum: field.minimum, label: t(field.label) });

        flag += 1;
      } else if (field.maximum < value.length) {
        tempformError = t("maxLimit", { maximum: field.maximum, label: t(field.label) });
        flag += 1;
      }
    }
    return { flag, tempformError };
  };
  const handleRadioChange = async (status) => {
    setIsGerman(status);
    setIsGermanOpen(false);
    validation(formState, formValues, formErrors, captchaStatus, false, agreementStatus, true, status);
  };
  // const clearAll = async () => {
  //   // setSxtended(false);
  // };
  const submitChange = async () => {
    props.setLoaderBox(true);
    let licensePlate = formValues.foreignPlate;
    if (isGerman) {
      licensePlate = `${formValues.licenseCode}-${formValues.licensePlate}`;
    }

    await postData({ licensePlate }, "guest/extent")
      .then((response) => {
        if (response.status === 200) {
          // setFormInput(initialFormInout);
          props.setLoaderBox(response.message);
        } else {
          props.setMessage({ type: 1, content: response.message, proceed: "Try Again" });
        }
        setExtendedLicnensePlate(licensePlate);
        setSxtended(true);
        props.setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
        props.setLoaderBox(false);
      });
  };
  const setAgreementStatusHandler = (status) => {
    setAgreementStatus(status);
    validation(formState, formValues, formErrors, captchaStatus, false, status, true, isGerman);
  };
  const handleChange = (event, id, type = "text", sub = null) => {
    // Getting current field
    const field = formState[id];
    if (sub === null) {
      let value = "";
      if (type === "checkbox") {
        value = event.target.checked;
      } else if (type === "select") {
        value = event.id;
      } else if (type === "text" || type === "number" || type === "password") {
        if (field.validation === "email") {
          value = event.target.value.toString().toUpperCase();
        } else {
          value = event.target.value
            .toString()
            .toUpperCase()
            .replace(/[^a-zA-Z0-9ÄÖÜß]/g, "");
        }
      } else if (type === "search") {
        value = JSON.stringify(event);
      } else if (type === "image" || type === "file") {
        value = event.target.files;
      } else if (type === "datetime" || type === "time") {
        value = event.toISOString();
      } else if (type === "date") {
        value = event.toISOString();
      } else {
        value = event.target.getAttribute("value");
      }
      const udpateValue = {
        ...formValues,
        [field.name]: value,
      };
      // Creating an updated field
      // updating the formm values
      setFormValues(udpateValue);
      // Validating the fields
      if (validation(formState, udpateValue, formErrors, captchaStatus, props.useCaptcha, agreementStatus, true, isGerman)) {
        // Here we can write any state updation
      }
    } else {
      const main = formState[sub.index];
      const field = main.forms[sub.multipleIndex][id];
      const udpateValue = { ...formValues };
      udpateValue[main.name][sub.multipleIndex][field.name] = event.target.value;
      setFormValues(udpateValue);
      // Validating the fields
      if (validation(formState, udpateValue, formErrors, captchaStatus, props.useCaptcha, agreementStatus, true, isGerman)) {
        // Here we can write any state updation
      }
    }
  };
  const [isGerman, setIsGerman] = useState(true);
  const [isGermanOpen, setIsGermanOpen] = useState(false);
  const selectRef = useRef(null);
  useEffect(() => {
    function handleClick(event) {
      if (!selectRef.current.contains(event.target)) {
        setIsGermanOpen(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  //State to store Captcha Status Validations Status
  return (
    <MainContainer className="notfixed">
      <Header1> </Header1>
      <RowContainer className="full">
        <ColumnContainer className="center shadow">
          <Overlay className={"plain"}>
            {parking.data.data ? (
              extended === false ? (
                <Page className={"plain"}>
                  <Header className={"popup"} dangerouslySetInnerHTML={{ __html: t("formHeader") }}></Header>
                  <Form className={"plain"}>
                    <Label>{isGerman ? t(formState[4].label) : t(formState[0].label)}</Label>
                    {!isGerman && <FormInput half="width70" animation={`sub`} placeholder={formState[0].placeHolder} key={`input` + 0} id={0} error={formErrors[formState[0].name]} value={formValues[formState[0].name]} {...formState[0]} onChange={handleChange} />}
                    {isGerman && <FormInput half="width30" animation={`sub`} placeholder={formState[3].placeHolder} key={`input` + 3} id={3} error={formErrors[formState[3].name]} value={formValues[formState[3].name]} {...formState[3]} onChange={handleChange} />}
                    {isGerman && <FormInput half="width70" animation={`sub`} placeholder={formState[4].placeHolder} key={`input` + 4} id={4} error={formErrors[formState[4].name]} value={formValues[formState[4].name]} {...formState[4]} onChange={handleChange} />}
                    <InputContainer className="radio" ref={selectRef}>
                      <div 
                        onClick={() => {
                          setIsGermanOpen(!isGermanOpen);
                        }}
                        className="label"
                      >
                        {isGerman ? <img src={germany} alt="Germany"></img> : <img src={inernational} alt="Germany"></img>}
                      </div>
                      {isGermanOpen && (
                        <div className="absolute">
                          <input id="germamy" type="radio" name="isGermany" onChange={() => handleRadioChange(true)} checked={isGerman}></input>
                          <label htmlFor="germamy">
                            <img src={germany} alt="Germany"></img>
                            <span>{t("germany")}</span>
                          </label>
                          <input id="foreign" type="radio" name="isGermany" onChange={() => handleRadioChange(false)} checked={!isGerman}></input>
                          <label htmlFor="foreign">
                            <img src={inernational} alt="Germany"></img>
                            <span>{t("foreignPlateRadio")}</span>
                          </label>
                        </div>
                      )}
                    </InputContainer>
                    <FormInput animation={`sub`} half={"first"} placeholder={formState[1].placeHolder} key={`input` + 1} id={1} value={formValues[formState[1].name]} {...formState[1]} />
                    <FormInput animation={`sub`} half={"last"} placeholder={formState[2].placeHolder} key={`input` + 2} id={2} value={formValues[formState[2].name]} {...formState[2]} />
                    <FormInput type="checkbox" placeholder={`acceptAgreement`} key={`acceptAgreement`} id={1} value={agreementStatus} onChange={setAgreementStatusHandler} />
                    <FormInput disabled={submitDisabled} type="submit" name="submit" value={t("confirm")} onChange={submitChange} />
                  </Form>
                </Page>
              ) : (
                <Page className={"plain"}>
                  <Header className={"popup"} dangerouslySetInnerHTML={{ __html: t("successfull") }}></Header>
                  <Center>
                    <TickIcon></TickIcon>
                  </Center>
                  <Center>
                    <p>{t("saved")}</p>
                  </Center>
                  <Form className={"plain"}>
                    <FormInput animation={`sub`} half={"first"} placeholder={formState[2].placeHolder} key={`input` + 2} id={2} value={formValues[formState[2].name]} {...formState[2]} />
                    <FormInput animation={`sub`} half={"last"} placeholder={formState[5].placeHolder} key={`input` + 5} id={5} value={extendedLicnensePlate} {...formState[5]} />
                    <Center>
                      <i>{t("info")}</i>
                    </Center>
                    {/* <FormInput disabled={false} type="submit" name="submit" value={t("ready")} onChange={clearAll} /> */}
                  </Form>
                </Page>
              )
            ) : (
              !isValid && (
                <Page className={"plain"}>
                  <Header className={"popup"} dangerouslySetInnerHTML={{ __html: t("unautherized") }}></Header>
                  <Center className="round">
                    <CloseIcon></CloseIcon>
                  </Center>
                  <Center>
                    <p>{t("unautherizedDesc")}</p>
                  </Center>
                </Page>
              )
            )}
          </Overlay>
        </ColumnContainer>
      </RowContainer>
      <Footer></Footer>
    </MainContainer>
  );
};

export default withLayout(Login);
