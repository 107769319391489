import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import Autocomplete from "react-google-autocomplete";
export const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  animation: ${(props) => props.animation};
  flex-direction: column;
  &.half:nth-child(odd) {
    width: calc(50% - 5px);
    margin-left: 5px;
  }
  &.half:nth-child(even) {
    width: calc(50% - 5px);
    margin-right: 5px;
  }
  &.info.first .info {
    border-radius: 10px 10px 0 0px;
  }
  &.info.last .info {
    border-radius: 0 0px 10px 10px;
  }
  &.info label {
    font-weight: 700;
  }
  &.info.first {
    margin: 30px 0px 1px 0;
  }
  &.info.last {
    margin: 0px 0px 30px 0px;
  }
  &.width30 {
    width: 60px;
    margin-right: 5px;
  }
  &.width70 {
    max-width: 120px;
    width: auto;
    margin-left: 0px;
  }
  &.auto {
    max-width: calc(100% - 5px);
    width: auto;
    min-width: 60%;
  }
  &.checkbox {
    flex: 100%;
    margin-bottom: 20px;
  }
  &.radio {
    flex-direction: row;
    margin-left: 5px !important;
    flex:1px;
    position:relative;max-width: 50px;
  }
  &.radio .absolute
  {
    position: absolute;
    top: 53px;
    z-index: 1;
    background: white;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    border-radius: 10px;    
    left: auto;
    right: 0;
  }
  &.radio .label
  {
    padding: 15px 13px;
    border-radius: 10px;
    background-color: #f3f8fb;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 700;
    display: -webkit-box;
    display: flex;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
  &.radio .label img
  {
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    width: 20px;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    padding: 10px 13px;
    border-radius: 10px;
    background-color: #f3f8fb;
    margin: 5px;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    transition: 0.2s;
  }
  input[type="radio"] + label span {
    margin-left: 10px;
  }
  input[type="radio"] + label:hover span,
  input[type="radio"]:checked + label span {
    display: block;
  }
  input[type="radio"] + label img {
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    width: 20px;
  }

  input[type="radio"]:checked + label {
    background-color: #e7f2f9;
  }
  &.half {
    width: 50%;
  }
  && {
    /* Styles to apply when parent has class shrink */
    .popup & {
      &:nth-of-type(even) {
        margin-left: 5px;
      }
      &:nth-of-type(odd) {
        margin-right: 5px;
      }
      &.textarea {
        flex: calc(100% - 0px);
        margin-right: 0px;
      }
    }
    .nowrap & {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    flex: 100%;
    margin: 0px !important;
    &.half:nth-child(odd) {
      width: calc(100%);
      margin-left: 0px;
    }
    &.half:nth-child(even) {
      width: calc(100%);
      margin-right: 0px;
    }
    &.width30 {
      width: 70px;
      margin-right: 5px !important;
      flex: inherit;
    }
    &.width70 {
      width: 150px;
      margin-left: 0px !important;
      flex: inherit;
    }
    &.info.first {
      margin: 30px 0px 1px 0 !important;
    }
    &.info.last {
      margin: 0px 0px 30px 0px !important;
    }
  }
  .options {
    min-height: 100px;
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    background-color: white;
    border: 1px solid rgb(224, 224, 227);
    border-radius: 12px;
    list-style: none;
    padding: inherit;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 36px;
    transition: all 1s ease-out 0s;
  }
  .options li.true {
    color: ${(props) => props.theme.lightSecForeground};
    font-weight: 500;
    border-bottom: 1px solid rgb(224, 224, 227);
    justify-content: left;
    display: flex;
    align-items: center;
  }
  .options li {
    cursor: pointer;
    border-bottom: 1px solid rgb(224, 224, 227);
    padding: 10px 0;
    margin: 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
  }
  .options li svg {
    color: ${(props) => props.theme.lightSecForeground};
    margin-left: 10px;
    transform: rotate(0deg);
    margin-left: 0px;
    height: 13px;
  }
  .options li:last-child {
    border-bottom: 0px solid rgb(224, 224, 227);
  }
  .options li:hover {
    color: ${(props) => props.theme.secBackground};
  }
  ${(props) =>
    props.children &&
    css`
      & input:focus ~ .floating-label,
      textarea:focus ~ .floating-label,
      textarea:not(:focus):valid ~ .floating-label,
      input:not(:focus):valid ~ .floating-label,
      input[type="date"]:not(:focus):invalid ~ .floating-label,
      .filter input[type="date"]:not(:focus):invalid ~ .floating-label,
      input[type="datetime-local"]:not(:focus):invalid ~ .floating-label,
      .filter input[type="datetime-local"]:not(:focus):invalid ~ .floating-label {
        top: -1px;
        left: 13px;
        right: 6px;
        font-size: 11px;
        opacity: 1;
        text-align: left;
        height: 19px;
        padding: 3px 0;
      }
    `}
`;
export const FileContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid Black;
  margin: 0 0px 10px 0;
  padding-left: 1em;
  font-weight: 700;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  color: #4f4f4f;
  background: #e7f2f9;
  text-align: center;
  button {
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
`;
export const Label = styled.label`
  position: absolute;
  pointer-events: none;
  top: 7px;
  left: 13px;
  font-size: 15px;
  transition: all 0.1s ease;
  color: ${(props) => props.theme.lightForeground};
  &.shrink {
    display: none;
  }
  &.error {
    color: red;
    color: red;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 93%;
  }
  &.checkbox {
    position: initial;
    pointer-events: initial;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    font-weight: 700;
  }
  svg {
    margin-right: 5px;
    color: green;
  }
`;

export const CheckBox = styled.input`
  margin: 0px;
  margin-right: 5px;
  margin-top: 5px;
  & ~ .checkmark {
    background-color: #ccc;
  }

  &:checked ~ .checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  &:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export const AutoComplete = styled(Autocomplete)`
  box-sizing: border-box;
  padding: 13px 10px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  height: 50px;
  margin: 0 0px 10px 0;
  padding-left: 1em;
  font-weight: 700;
  box-shadow: none;
  -webkit-appearance: none;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.foreground};
  background: ${(props) => props.theme.background};
  &.shrink {
    padding: 1.6em 13px 0;
  }
`;
export const Input = styled.input`
  box-sizing: border-box;
  padding: 13px 10px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  height: 50px;
  margin: 0 0px 10px 0;
  padding-left: 1em;
  font-weight: 700;
  box-shadow: none;
  appearance: none;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.foreground};
  background: ${(props) => props.theme.background};
  font-size: 14px;
  &.info {
    background-color: #f2f7fa;
    margin: 0 0px 0px 0;
    border-radius: 0px;
    font-weight: normal;
    height: 55px;
  }
  &.info:first-child {
    border-radius: 10px;
  }
  &.info.shrink {
    padding: 1.5em 13px 0;
  }
`;
export const DatetimeInput = styled(DatePicker)`
  box-sizing: border-box;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  margin: 0 0px 10px 0;
  height: 50px;
  padding-left: 1em;
  font-weight: 700;
  box-shadow: none;
  -webkit-appearance: none;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.foreground};
  background: ${(props) => props.theme.background};
  &.shrink {
    padding: 1.6em 13px 0;
    color: transparent;
  }
`;
export const ShowDate = styled.span`
  position: absolute;
  clip-path: unset;
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: initial;
  padding: 0;
  width: 1px;
  white-space: nowrap;
  top: 26px;
  left: 14px;
  font-size: 13px;
  font-weight: 600;
  pointer-events: none;
`;
export const Button = styled.button`
  background: ${(props) => props.theme.themeBackground};
  border-radius: 12px;
  color: ${(props) => props.theme.themeForeground};
  transition: all 0.5s ease;
  margin-top: 10px;
  max-width: 400px;
  padding: 15px 20px;
  min-width: 155px;
  border-color: ${(props) => props.theme.border};
  border-style: solid;
  font-size: 15px;
  cursor: pointer;
  border-width: ${(props) => props.theme.borderThinkness};
  &.close {
    background: transparent;
    color: gray;
    margin-right: 1em;
    min-width: 120px;
    border: 1px solid;
  }
  &:disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledForeground};
    cursor: not-allowed;
  }
  && {
    /* Styles to apply when parent has class shrink */
    .plain & {
      max-width: auto;
      width: 100%;
    }
    /* Styles to apply when parent has class shrink */
    .nowrap & {
      margin-top: 0;
      margin-bottom: 10px;
      margin-right: 20px;
      margin-left: 0;
    }
    @media (max-width: 768px) {
      .nowrap & {
        margin-right: 0px;
      }
    }
  }
`;
export const TextArea = styled.textarea`
  box-sizing: border-box;
  font-family: inherit;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  height: 100px;
  margin: 0 0px 10px 0;
  padding-left: 1em;
  font-weight: 700;
  box-shadow: none;
  -webkit-appearance: none;
  transition: all 0.2s ease-out 0s;
  padding: 13px 10px;
  color: ${(props) => props.theme.foreground};
  background: ${(props) => props.theme.background};
  &.shrink {
    padding: 2em 13px 0;
  }
`;
