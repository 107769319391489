// its middlewear to handle reducer call to update a state

import { getData } from "../../../backend/api";

const fetchParking = (parkingId) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PARKING_LOADING",
    });
    getData({}, `guest/extensioninfo/${parkingId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "FETCH_PARKING_SUCCESS",
            payload: response.data,
          });
        } else {
          dispatch({
            type: "FETCH_PARKING_ERROR",
            payload: "validationFailed",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_PARKING_ERROR",
          payload: error,
        });
      });
  };
};
const clearParking = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_PARKING",
    });
  };
};
export { fetchParking, clearParking };
