import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
`;
export const ListItemHead = styled.div`
  font-weight: bold;
  display: flex;
  margin: 0;
  color: #1381c5;
  padding: 1em 1em 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid white;
  flex-direction: column;
  &:first-child {
    margin-top: 0;
  }
  div {
    flex: 1 1 100%;
  }
  p {
    flex: 1 1 100%;
    text-transform: initial;
    font-weight: initial;
    color: #858585;
    padding: 0;
    margin: 5px 0;
  }
  .box{
    border-radius: 10px;
    background: #f3f8fb;
    padding: 10px;
    margin-top: 15px;
  }
`;
export const ListItem = styled.div`
  display: flex;
  padding: 0.5em 1em;
  flex-direction: column;
  &.row {
    flex-direction: row;
  }
  svg {
    margin-top: 3px;
    margin-right: 10px;
  }
  span {
    margin-right: auto;
    margin-bottom: 5px;
  }
  span:first-child {
    flex: 1 1 150px;
  }
  span:last-child {
    font-weight: bold;
    flex: 1 1 calc(100% - 150px);
  }
  @media (max-width: 768px) {
    span:first-child {
      flex: auto;
    }
    span:last-child {
      font-weight: bold;
      flex: auto;
    }
  }
`;
export const ListItemBold = styled.div`
  display: flex;
  font-weight: 500;
  padding: 0.5em 1em;
  svg {
    margin-right: 10px;
  }
`;

export const ListItemQuarter = styled.div`
  display: flex;
  flex: 1 1 25%;
  span {
    margin-left: 10px;
    cursor: pointer;
  }
  &.paid {
    color: green;
  }
  &.canceled {
    color: red;
  }
  &.open {
    color: yellow;
  }
  @media screen and (max-width: 768px) {
    &.hm {
      display: none;
    }
  }
`;
export const ToolTipContainer = styled.span`
  position: relative;
`;

export const ToolTip = styled.div`
  position: absolute;
  top: 0;
  left: 19px;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  margin-bottom: 10px;
  cursor: initial;
  &.language {
    top: 27px;
    left: -80px;
    font-size: 18px;
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
`;
